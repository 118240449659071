export const initRuntimeEnv = async () => {
  let windowLocation = window.location.origin
  return fetch(`${windowLocation}/runtime-env.js/${windowLocation.includes('localhost') ? 'development' : 'production'}`)
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      window.__RUNTIME_CONFIG__ = res?.environmentVariables;
      return res?.environmentVariables;
    })
  }